<script>
import Form from '../../form';
import request from '../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'select',
          field: 'bsDirectId',
          title: '直营体系名称',
          restful: '/mdm/mdmDirectSystemController/unAddSelect',
          props: {
            loading: false,
            clearable: true,
            filterable: true,
            remote: true,
            remoteParams: 'directSystemName',
          },
          on: {
            change: (e) => this.bsDirectIdChange(e),
          },
          optionsKey: { label: 'directSystemName', value: 'bsDirectId' },
        },
        {
          type: 'input',
          title: '直营体系编码',
          field: 'directSystemCode',
          props: {
            clearable: true,
          },
          validate: [
            {
              trigger: 'blur',
              pattern: '^[a-zA-Z0-9]*$',
              message: '仅能输入英文和数字的组合',
            },
          ],
        },
        {
          type: 'hidden',
          title: '直营体系名称',
          field: 'directSystemName',
        },
      ],
    };
  },
  mounted() {

  },
  methods: {
    // 直营体系change事件
    bsDirectIdChange(e) {
      const bsDirectId = this.getRule('bsDirectId');
      const selectedData = bsDirectId.options.find((item) => item.bsDirectId === e);
      this.setValue({
        directSystemName: selectedData.directSystemName,
      });
    },
    submit() {
      const formData = this.getFormData();
      request.post('/mdm/mdmDirectSystemController/add', formData).then((res) => {
        if (res.success) {
          this.$message.success('操作成功');
          this.$emit('onClose');
          this.$emit('reset');
        }
      });
    },
  },
};
</script>

<style>

</style>
